.calendar-table {
    width: 99%;
    table-layout: fixed;
    margin-left: 10px;
  }
  
  .calendar-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 115px;
  }
  
  .calendar-day {
    position: relative;
  }
  
  .date-container {
    cursor: pointer;
    padding: 30px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .date-container.disable-date {
    background-color: rgb(228, 219, 219);
    opacity: 0.7;
  }
  .date-container.all_bookedslot {
    background-color: rgb(228, 219, 219);
    opacity: 0.7;
  }
  
  .date-container.selected {
    background-color: #e0e0e0;
  }
  
  .date-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .current-date {
    margin-top: 10px;
    text-align: center;
  }
  
  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  
  }
  
  .birthday_img {
    margin-top: 200px;
  }
  
  .month-container {
    margin: 0 20px;
  }
  
  .take-action-button {
    background-color: #4c4e4c;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  
  section.brithday-sec {
    margin-top: -36px;
    padding-top: 0px !important;
    background-color: #fff;
    position: relative;
  }
  
  .calendar-container {
    margin-top: 40px;
    display: auto;
    flex-wrap: wrap;
    gap: 130px;
  }
  
  .calendar-date {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .calendar-date.selected {
    background-color: #e0e0e0;
  }
  
  .sunday {
    color: red;
  
  }
  
  .holiday-label {
    color: red;
  }
  
  .calendar-header {
    margin-top: 10px;
    display: flex;
  
    align-items: center;
    justify-content: center;
  }
  
  .calendar-header button,
  .calendar-header .take-action-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #4c4e4c;
    color: #fff;
  }
  
  .month-container {
    display: flex;
    align-items: center;
  }
  
  .take-action-button {
    margin-left: 20px;
  }
  
  .slot-buttons {
    margin-top: 0px;
  
  }
  
  .arrow-icon {
    font-size: 24px;
  }
  
  .slot-buttons button,
  .time-slots button {
  
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    margin-right: 7px;
    padding: 1px 10px;
  }
  
  .slot-buttons button {
    background-color: #3498db;
    color: #fff;
  
  
  }
  .morning-slot.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .evening-slot.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  
  .time-slots button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #2ecc71;
    color: #fff;
    border: 1px solid white;
    border-radius: 3px;
  }
  
  .container {
    margin-top: 20px;
  }
  
  .brithdayview-header {
    margin-top: 200px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  @media (max-width: 767px) {
    .container {
      margin-top: 20px;
      overflow-x: auto;
    }
  
    .table th,
    .table td {
      padding: 8px;
    }
  
    .brithdayview-header {
      margin-top: 100px;
    }
  
    .pop-up-form {
      padding: 10px;
    }
  
  
  }
  
  
  
  @media only screen and (max-width: 767px) {
    .big-container {
      width: 100%;
    }
  
   
    .calendar-container {
     
      gap: 10px;
    }
  
    .calendar-day {
    
      width: 100%;
    }
  
    .take-action-button {
    
      margin-left: 0;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .big-container {
      width: 100%;
    }
  
    .calendar-table {
      table-layout: auto;
      width: 100%;
      margin-left: 0;
    }
    .birthday_img {
      margin-top: 30px;
    }
    .calendar-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .calendar-day {
      width: 14.28%;
    }
  }